<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="条件类型" prop="type">
        <a-select style="width: 250px" v-model="form.type" placeholder="请输入条件类型">
          <a-select-option v-for="(item, index) in this.customDict.AppellationConditionTypeEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="活动称号类型 1:普通称号,2:星河称号" prop="actType" >
      </a-form-model-item>-->
<!--      <a-form-model-item label="级别数量" prop="levelNum" >
        <a-input v-model="form.levelNum" placeholder="请输入级别数量" />
      </a-form-model-item>
      <a-form-model-item label="组局数量" prop="groupNum" >
        <a-input v-model="form.groupNum" placeholder="请输入组局数量" />
      </a-form-model-item>-->
      <a-form-model-item label="达成条件描述" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>

<!--      <a-form-model-item label="分类id" prop="classId" >
        <a-input v-model="form.classId" placeholder="请输入分类id" />
      </a-form-model-item>
      <a-form-model-item label="逻辑删除标记 1:删除,0:未删除" prop="isDeleted" v-if="formType === 1">
        <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记 1:删除,0:未删除" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
        <a-input v-model="form.version" placeholder="请输入乐观锁" />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAppellationCondition, addAppellationCondition, updateAppellationCondition } from '@/api/config/appellationCondition'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        actType: null,

        levelNum: null,

        groupNum: null,

        classId: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '称号类型 1:组局,2:好友组局不能为空', trigger: 'change' }
        ],

        actType: [
          { required: true, message: '活动称号类型 1:普通称号,2:星河称号不能为空', trigger: 'change' }
        ],

        levelNum: [
          { required: true, message: '级别数量不能为空', trigger: 'blur' }
        ],

        groupNum: [
          { required: true, message: '组局数量不能为空', trigger: 'blur' }
        ],

        classId: [
          { required: true, message: '分类id不能为空', trigger: 'blur' }
        ],

        isDeleted: [
          { required: true, message: '逻辑删除标记 1:删除,0:未删除不能为空', trigger: 'blur' }
        ],

        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]

      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,

        type: null,

        actType: null,

        levelNum: null,

        groupNum: null,

        classId: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAppellationCondition(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAppellationCondition(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAppellationCondition(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
