import request from '@/utils/request'

// 查询称号条件配置列表
export function listAppellationCondition(query) {
  return request({
    url: '/config/appellation/condition/list',
    method: 'get',
    params: query
  })
}

// 查询称号条件配置详细
export function getAppellationCondition(id) {
  return request({
    url: '/config/appellation/condition/' + id,
    method: 'get'
  })
}

// 新增称号条件配置
export function addAppellationCondition(data) {
  return request({
    url: '/config/appellation/condition',
    method: 'post',
    data: data
  })
}

// 修改称号条件配置
export function updateAppellationCondition(data) {
  return request({
    url: '/config/appellation/condition',
    method: 'put',
    data: data
  })
}

// 删除称号条件配置
export function delAppellationCondition(id) {
  return request({
    url: '/config/appellation/condition/' + id,
    method: 'delete'
  })
}
